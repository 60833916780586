import { useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { store } from '~/pages/heineken_template/_private/store';
/** 散戶在圖表內直接操作 charting 時，對指標群作出加載或移除 */
export const useStartFilteringIndicators = (indicators) => {
    const symbol = useSnapshot(store.charting).symbol;
    useEffect(() => {
        store.charting.replaceIndicators(indicators);
    }, [symbol]);
};
